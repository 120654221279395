import React, { useState } from 'react';
import { useField } from '@formiz/core';
import PropTypes from 'prop-types';

const FormInput = (props) => {
  const {
    errorMessage,
    id,
    isValid,
    isPristine,
    isSubmitted,
    resetKey,
    setValue,
    value,
  } = useField(props);

  const { label, type, required } = props;

  const [isFocused, setIsFocused] = useState(false);

  const showError = !isValid && !isFocused && (!isPristine || isSubmitted);

  return (
    <>
      <label
        className="block text-slate-800 text-xs lg:text-sm font-bold mb-1"
        htmlFor={id}
      >
        {label}
        {required && '*'}
      </label>
      <input
        key={resetKey}
        id={id}
        className="border h-10 rounded w-full border-slate-300 text-sm text-slate-800 px-2"
        name={name}
        type={type || 'text'}
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        aria-invalid={!isValid}
        aria-describedby={!isValid ? `${id}-error` : null}
      />
      {showError && (
        <div id={`${id}-error`} className="text-sm mt-1 text-red-700">
          {errorMessage}
        </div>
      )}
    </>
  );
};

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.string,
};

export default FormInput;
