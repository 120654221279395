import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import AssessmentForm from '@/components/AssessmentForm';
import Modal from '@/components/Modal';
import OfferContent from '@/components/OfferContent';

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    weight: '0',
    targetWeight: '0',
    height: '0',
    age: '0',
  });

  const listBenefits = [
    'Find out how much weight you can probably lose when using KETO LIFE®.',
    'Find out if KETO LIFE® is personally suitable to you and your schedule.',
    'KETO LIFE® is available over-the-counter and without a prescription.',
  ];

  function assessmentSubmit(values) {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setModalData({
        weight: values.weight,
        targetWeight: values.targetWeight,
        height: values.height,
        age: values.age,
      });
      setShowModal(true);
    }, 2000);
  }

  return (
    <>
      <Helmet>
        <title>Keto Life® Gummies</title>
      </Helmet>

      <div className="w-full sm:w-11/12 md:w-10/12 xl:w-8/12 2xl:w-7/12 mx-auto h-full">
        <div className="lg:flex p-4">
          <div className="lg:flex-1 lg:p-4">
            <ul className="lg:mt-8">
              {listBenefits.map((item, key) => {
                return (
                  <li
                    key={`list-bene-${key}`}
                    className="mb-6 text-lg text-slate-800 flex items-center"
                  >
                    <div className="flex-none mr-2 text-slate-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-12 h-12"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="flex-1">{item}</div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="lg:flex-1 lg:p-4">
            <div className="lg:mt-[-64px]">
              <AssessmentForm
                onSubmit={assessmentSubmit}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>

      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          {(onClose) => <OfferContent mainMsg={modalData} />}
        </Modal>
      )}
    </>
  );
};

export default HomePage;
