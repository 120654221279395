import { createSlice } from '@reduxjs/toolkit';

export const mySlice = createSlice({
  name: 'mySlice',
  initialState: {},
  reducers: {
    myReducerFn: (state, { payload }) => {},
  },
});

export const { myReducerFn } = mySlice.actions;

export default mySlice.reducer;
