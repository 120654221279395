import React from 'react';
import PropTypes from 'prop-types';

// components
import Logo from '@/components/Logo';

// Images
import reviewsImage from '@/assets/img/reviews.jpg';

const AppHeader = ({ scrollToForm }) => {
  return (
    <header className="bg-green-600">
      <div className="w-full sm:w-11/12 md:w-10/12 xl:w-8/12 2xl:w-7/12 mx-auto h-full">
        <div className="lg:flex items-center h-full p-4">
          <div className="lg:flex-1 lg:p-4">
            <div className="text-white mb-4 flex">
              <div className="mr-auto">
                <Logo />
              </div>
              <div className="bg-white text-black font-bold ml-auto flex items-center px-3 rounded">
                NEW!
              </div>
            </div>
            <h1 className="font-bold text-2xl mb-4 text-white">
              Individual prognosis for weight loss
            </h1>
            <p className="text-white text-lg">
              The application has been successful and proven by research. You
              can use this data to calculate how long it will likely take you to
              reach your desired weight.
            </p>
            <button
              className="lg:hidden bg-white font-bold py-2 w-full mt-4 rounded flex items-center justify-center mb-4"
              onClick={scrollToForm}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                />
              </svg>
              Start prognosis
            </button>
          </div>
          <div className="lg:flex-1 lg:p-4">
            <img
              className="rounded-xl w-full"
              src={reviewsImage}
              alt="review keto gumies"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

AppHeader.propTypes = {
  scrollToForm: PropTypes.func.isRequired,
};

export default AppHeader;
