import React, { useRef } from 'react';
import AppHeader from '@/components/AppHeader';
import { Outlet } from 'react-router-dom';

const DefaultLayout = () => {
  const startPrognosisForm = useRef(null);
  const scrollToForm = () => startPrognosisForm.current.scrollIntoView();

  return (
    <div>
      <AppHeader scrollToForm={scrollToForm} />
      <Outlet context={[startPrognosisForm]} />
    </div>
  );
};

export default DefaultLayout;
