import { useRef, useEffect } from 'react';

const usePortal = (elId) => {
  const rootElRef = useRef(document.createElement('div'));
  let targetEl = document.getElementById(elId);

  const setPortal = () => {
    if (!targetEl) {
      const el = document.createElement('div');
      el.setAttribute('id', elId);
      document.body.appendChild(el);
      targetEl = document.getElementById(elId);
    }
    targetEl.appendChild(rootElRef.current);
  };

  const unsetPortal = () => {
    rootElRef.current.remove();
  };

  useEffect(() => {
    setPortal();

    return () => unsetPortal();
  }, [elId]);

  return rootElRef.current;
};

export default usePortal;
