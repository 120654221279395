import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Layouts
import DefaultLayout from '@/layout/DefaultLayout';

// Pages
import HomePage from '@/pages/index';

const renderRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route path="/" element={<HomePage />} />
      </Route>
    </Routes>
  );
};

export default renderRoutes;
