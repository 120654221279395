import React, { useState } from 'react';
import { useField } from '@formiz/core';
import PropTypes from 'prop-types';

const FormSelect = (props) => {
  const { label, required } = props;

  const {
    errorMessage,
    id,
    isValid,
    isPristine,
    isSubmitted,
    resetKey,
    setValue,
  } = useField(props);

  const [isFocused, setIsFocused] = useState(false);

  const showError = !isValid && !isFocused && (!isPristine || isSubmitted);

  const ages = [17];
  for (let i = 18; i < 99; i++) {
    ages.push(i);
  }

  return (
    <>
      <label
        className="block text-slate-800 text-sm font-bold mb-1"
        htmlFor={id}
      >
        {label}
        {required && '*'}
      </label>
      <select
        key={resetKey}
        id={id}
        className="block border h-10 w-full rounded border-slate-300 px-2 text-sm text-slate-800"
        name={name}
        onChange={(e) => setValue(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        aria-invalid={!isValid}
        aria-describedby={!isValid ? `${id}-error` : null}
      >
        <option>Please select age</option>
        {ages.map((age) =>
          age < 18 ? (
            <option key={age} value="18">
              Under 18
            </option>
          ) : (
            <option key={age} value={age}>
              {age} Years
            </option>
          )
        )}
      </select>
      {showError && (
        <div id={`${id}-error`} className="text-sm mt-1 text-red-700">
          {errorMessage}
        </div>
      )}
    </>
  );
};

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.string,
};

export default FormSelect;
