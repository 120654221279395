import React from 'react';
import { Formiz, FormizStep, useForm } from '@formiz/core';
import { isMinNumber, isMaxNumber } from '@formiz/validations';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router-dom';

import FormRadioInput from '../FormRadioInput';
import FormInput from '../FormInput';
import FormSelect from '../FormSelect';

const AssessmentForm = ({ onSubmit, isLoading }) => {
  const form = useForm();
  const [startPrognosisForm] = useOutletContext();

  return (
    <div
      ref={startPrognosisForm}
      className="bg-slate-200 border border-slate-300 p-6 rounded-xl shadow-xl"
    >
      <Formiz connect={form} onValidSubmit={onSubmit}>
        <form noValidate onSubmit={form.submitStep}>
          <div className="text-slate-800 p-2 text-center w-full mb-4 rounded-full font-bold">
            Step {(form.currentStep && form.currentStep.index + 1) || 0} of{' '}
            {form.steps.length}
          </div>
          <div>
            <FormizStep name="step1">
              <h3 className="text-slate-800 text-sm font-bold mb-1">Gender</h3>
              <div className="flex mb-4">
                <div className="flex-auto pr-2">
                  <FormRadioInput
                    id="male"
                    label="Male"
                    name="gender"
                    value="male"
                    defaultChecked
                  />
                </div>
                <div className="flex-auto pl-2">
                  <FormRadioInput
                    id="female"
                    label="Female"
                    name="gender"
                    value="female"
                  />
                </div>
              </div>
              <div className="flex mb-4">
                <div className="flex-auto pr-2">
                  <FormInput
                    id="weight"
                    name="weight"
                    label="Weight (lbs)"
                    required="Weight is required"
                    type="number"
                    validations={[
                      {
                        rule: isMinNumber(80),
                        message: 'The weight should be above 80',
                      },
                      {
                        rule: isMaxNumber(500),
                        message: 'The weight should be under 500',
                      },
                    ]}
                  />
                </div>
                <div className="flex-auto pl-2">
                  <FormInput
                    id="targetWeight"
                    name="targetWeight"
                    label="Target Weight (lbs)"
                    required="Target Weight is required"
                    type="number"
                    validations={[
                      {
                        rule: isMinNumber(80),
                        message: 'The weight should be above 80',
                      },
                      {
                        rule: isMaxNumber(500),
                        message: 'The weight should be under 500',
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="mb-4">
                <FormInput
                  id="height"
                  name="height"
                  label="Height (ft)"
                  required="Height is required"
                  type="number"
                  validations={[
                    {
                      rule: isMinNumber(3),
                      message: 'The height should be above 3',
                    },
                    {
                      rule: isMaxNumber(8),
                      message: 'The height should be under 8',
                    },
                  ]}
                />
              </div>
              <div className="mb-4">
                <FormSelect
                  id="age"
                  name="age"
                  label="Age"
                  required="Age is required"
                />
              </div>
            </FormizStep>

            <FormizStep name="step2">
              <h3 className="font-bold mb-2">
                How motivated are you to lose weight?
              </h3>
              <div className="flex mb-4">
                <div className="flex-auto pr-2">
                  <FormRadioInput
                    id="little"
                    label="Little"
                    name="motivation"
                  />
                </div>
                <div className="flex-auto pl-2">
                  <FormRadioInput
                    id="medium"
                    label="Medium"
                    name="motivation"
                  />
                </div>
                <div className="flex-auto pl-2">
                  <FormRadioInput id="very" label="Very" name="motivation" />
                </div>
              </div>
              <h3 className="font-bold mb-2">How active are you?</h3>
              <div className="flex mb-4">
                <div className="flex-auto pr-2">
                  <FormRadioInput id="little" label="Little" name="sports" />
                </div>
                <div className="flex-auto pl-2">
                  <FormRadioInput id="medium" label="Medium" name="sports" />
                </div>
                <div className="flex-auto pl-2">
                  <FormRadioInput id="very" label="Very" name="sports" />
                </div>
              </div>
            </FormizStep>
          </div>

          <div className="flex mt-6">
            <div className="mr-auto">
              {!form.isFirstStep && (
                <button
                  className="bg-slate-200 hover:bg-slate-300 px-8 py-3 font-bold rounded"
                  type="button"
                  onClick={form.prevStep}
                >
                  Back
                </button>
              )}
            </div>

            <div className="ml-auto">
              {form.isLastStep ? (
                <button
                  className="bg-yellow-500 hover:bg-amber-500 px-8 py-3 font-bold rounded"
                  type="submit"
                  disabled={
                    isLoading || (!form.isValid && form.isStepSubmitted)
                  }
                >
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
              ) : (
                <button
                  className="bg-yellow-500 hover:bg-amber-500 px-8 py-3 font-bold rounded"
                  type="submit"
                  disabled={!form.isStepValid && form.isStepSubmitted}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </form>
      </Formiz>
    </div>
  );
};

AssessmentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default AssessmentForm;
