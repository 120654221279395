import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

export default function FormRadioInput({ label, name, id, ...props }) {
  return (
    <div className="radio-input">
      <input type="radio" id={id} name={name} {...props} />
      <label htmlFor={name}>{label}</label>
    </div>
  );
}

FormRadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
