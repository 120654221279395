import { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import usePortal from '@/hooks/usePortal';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const Modal = ({ children, onClose }) => {
  const modalRef = useRef();
  const [active, setActive] = useState(false);

  useOnClickOutside(modalRef, () => handleOnClose());

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 10);
  }, []);

  const handleOnClose = () => {
    setActive(false);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  return createPortal(
    <div>
      {/* modal curtin overlay */}
      <div
        className={`${
          active ? 'opacity-80 visible' : 'invisible opacity-0'
        } transition-all duration-200 fixed inset-0 bg-slate-900 overflow-hidden`}
        onClick={handleOnClose}
        role="presentation"
      />
      {/* modal inner box */}
      <div className="flex items-start justify-center fixed z-30 inset-0 overflow-y-auto">
        <section ref={modalRef} className="w-full h-auto pb-4 bg-white">
          <div
            className={`${
              active
                ? 'translate-y-4  opacity-100 visible'
                : 'translate-y-0 invisible opacity-0'
            } transform transition-all duration-200 h-full shadow-md relative`}
          >
            {/* <button
              onClick={handleOnClose}
              className="absolute right-3 top-3 active:text-darkBlue-600 hover:text-darkBlue-300 text-darkBlue-400 transition-colors duration-200"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-6 -6 24 24"
                width="28"
              >
                <path
                  fill="currentColor"
                  d="M7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485 2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535 3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z"
                />
              </svg>
            </button> */}
            {children(handleOnClose)}
          </div>
        </section>
      </div>
    </div>,
    usePortal('modal-root')
  );
};

Modal.propTypes = {
  children: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
